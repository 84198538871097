import { useAvatarStore } from 'services/AvatarService.new';
import { Color } from 'three';

function updateConfiguration(clonedScene, appearance) {
  try {
    const appearanceValues = useAvatarStore.getState().computeAppearanceValues(appearance);

    const enable = function(o) {
      while (o !== clonedScene) {
        o.visible = true;
        o = o.parent;
      }
    };

    clonedScene.traverse(object => {
      if (!object.isBone) {
        object.dontRenderIfInvisible = true;
        object.visible = false;
      }

      if (object.isMesh) {
        const material = object.material.name;

        let name = '';
        if (object.parent.type === 'Object3D') {
          name = object.name;
        } else if (object.parent.type === 'Group') {
          name = object.parent.name;
        } else if (object.parent.type === 'Bone') {
          name = object.name;
        }

        if (material === 'Glass_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.transparent = true;
          clonedMaterial.color = new Color(0x9cceca);
          clonedMaterial.opacity = 0.25;
          clonedMaterial.roughness = 0;
          object.material = clonedMaterial;
        }

        if (material === 'GlassRim_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.transparent = true;
          clonedMaterial.color = new Color(0x98802d);
          clonedMaterial.roughness = 0;
          clonedMaterial.metallness = 0;
          object.material = clonedMaterial;
        }

        if (material === 'Sunglasses_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.transparent = true;
          clonedMaterial.color = new Color(0x000000);
          clonedMaterial.opacity = 0.75;
          clonedMaterial.roughness = 0;
          object.material = clonedMaterial;
        }

        // colors
        if (material === 'Skin_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.skin.color.default);
          clonedMaterial.roughness = 0.5;
          object.material = clonedMaterial;
        }

        if (material === 'Hair_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.hair.color.default);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        if (material === 'FacialHair_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.facial_details.color.default);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        if (material === 'Pants_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.bottom.color.default);
          clonedMaterial.roughness = 0.75;
          object.material = clonedMaterial;
        }

        if (material === 'AccessoryA_MTL' || material === 'AccessoryB_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.accesoires.color.default);
          clonedMaterial.roughness = 0.5;
          object.material = clonedMaterial;
        }

        if (material === 'Shoes_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.shoes.color.default);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        // top => primary color
        if (material === 'Hoodie_MTL' || material === 'Braces_MTL' || material === 'Jacket_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.top.color.jacket);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        // top => secondary color
        if (material === 'Shirt_MTL' || material === 'Sweater_MTL' || material === 'SweatshirtSecondary_MTL') {
          const clonedMaterial = object.material.clone();
          clonedMaterial.color = new Color(appearanceValues.top.color.shirt);
          clonedMaterial.roughness = 1;
          object.material = clonedMaterial;
        }

        if (name === appearanceValues.top.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.hair.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.facial_details.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.facial_features.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.bottom.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.accesoires.style.default.name) {
          enable(object);
        }

        if (name === appearanceValues.shoes.style.default.name) {
          enable(object);
        }

        if (name.includes('msm_head_main')) {
          enable(object);
        }
      }
    });
    clonedScene.visible = true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('updateConfiguration', e);
  }
}
export { updateConfiguration };
