import { Box, IconContainer } from './styles';
import { useAvatarStore } from 'services/AvatarService.new';
import Indicator from '../../Indicator';
import { useUserStore } from '../../../../../services/UserService';

export default function Option({
  option,
  optionIndex,
  appearIndex,
  selected,
  categoryId,
  panelIndex,
  panelType,
  selectedTopPageIndex,
}) {
  const selectCustomizationOption = useAvatarStore(state => state.selectCustomizationOption);

  const color = panelType === 'color' ? option : 'black';
  const icon = panelType === 'style' ? option.icon : null;
  const show = option.name !== 'HIDE_OPTION';
  const use = option.use === undefined ? true : option.use;

  const handleOptionClick = () => {
    if (categoryId === 'top') {
      selectCustomizationOption(categoryId, panelIndex, optionIndex, selectedTopPageIndex);
    } else {
      selectCustomizationOption(categoryId, panelIndex, optionIndex);
    }
    useUserStore.getState().previewAppearance(useAvatarStore.getState().getAppearance());
  };

  const showCheckIcon =
    categoryId === 'top' && panelIndex > 0
      ? selectedTopPageIndex === 0
        ? selected.includes('jacket')
        : selected.includes('shirt')
      : selected.includes('default');

  return (
    use && (
      <Box color={color} icon={icon} appearIndex={appearIndex} onClick={handleOptionClick} show={show}>
        <IconContainer icon={icon} />
        <Indicator showCheckIcon={showCheckIcon} />
      </Box>
    )
  );
}
