import { Container, OptionPanelColumn } from './styles';
import React, { useEffect } from 'react';
import CategoryPanel from './CategoryPanel';
import OptionsPanel from './OptionsPanel';
import { useAvatarStore } from 'services/AvatarService.new';
import { useWindowStore } from 'services/WindowService';
import { useDeviceStore } from 'services/DeviceService';
import { useUserStore } from '../../../services/UserService';

export function CustomizationUi({ onDone }) {
  const customizationConfiguration = useAvatarStore(state => state.customizationConfiguration);
  const selectedCategory = customizationConfiguration.find(ao => ao.selected);
  const isMobileBreakpoint = useWindowStore(state => state.isMobile);
  const isMobileOnly = useDeviceStore(state => state.device.isMobileOnly);
  const simplify = isMobileOnly || isMobileBreakpoint;

  useEffect(() => {
    return () => {
      useAvatarStore.getState().deselectCustomizationCategories();
    };
  }, []);

  return (
    <Container>
      <CategoryPanel
        simplify={simplify}
        customizationConfiguration={customizationConfiguration}
        onDone={onDone}
        randomize={() => {
          useAvatarStore.getState().randomize();
          useUserStore.getState().previewAppearance(useAvatarStore.getState().getAppearance());
        }}
      />
      {!simplify && (
        <OptionPanelColumn>
          {selectedCategory &&
            selectedCategory.optionPanels.map((op, opIndex) => {
              return (
                <OptionsPanel
                  key={selectedCategory.id + opIndex}
                  categoryId={selectedCategory.id}
                  panelIndex={opIndex}
                  panel={op}
                />
              );
            })}
        </OptionPanelColumn>
      )}
    </Container>
  );
}
