import React, { useEffect } from 'react';
import { useSceneStore } from 'services/SceneService';
import { usePlayerStore } from 'services/PlayerService';
import Environment from 'components/Play/Environment';
import FittingRoom from 'components/Play/FittingRoom';
import Character from 'components/Play/Character.new';
import PointerControls from 'components/PointerControls';
import CameraController from 'components/Play/CameraController';
import { MODE_FITTING } from 'services/PlayerService/constants';
import { useEnvironmentStore } from 'components/Play/Environment/store';
import { useAvatarStore } from '../../../services/AvatarService.new';

export default function AvatarScene() {
  const appearance = useAvatarStore(state => state.getAppearance());

  useEffect(() => {
    useSceneStore.getState().setEmptyScene();
    useEnvironmentStore.getState().setPresetByName('fittingRoom');
    usePlayerStore.getState().setMode(MODE_FITTING);
  }, []);

  const position = usePlayerStore.getState().position;
  const rotation = usePlayerStore.getState().rotation;
  const playerData = {
    position,
    rotation,
    velocity: [0, 0, 0, 0],
  };
  return (
    <>
      <Environment />
      <FittingRoom />
      <Character appearance={appearance} playerData={playerData} name={'Character'} />
      <PointerControls />
      <CameraController />
    </>
  );
}
