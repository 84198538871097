import React, { useCallback, useEffect, useState } from 'react';
import ThreeCanvas from 'three/ThreeCanvas';
import { CustomizationUi } from './CustomizationUi';
import AvatarScene from './AvatarScene';
import { useAvatarStore } from 'services/AvatarService.new';
import { useUserStore } from 'services/UserService';
import { useHistory } from 'react-router-dom';
import VisualLoader from '../Play/VisualLoader';
import { useEventStore } from 'services/EventService';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import { useGlobalHubStore } from 'services/GlobalHubService';

function Done() {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return null;
}

function Error({ error }) {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return <ErrorFallbackUI message={error.message} />;
}

export default function CreateAvatar() {
  const history = useHistory();
  const useAvatars = useEventStore(state => state.event.useAvatars);

  const doneCallback = useCallback(() => {
    useUserStore.getState().changeAppearance(useAvatarStore.getState().getAppearance());
    useGlobalHubStore.getState().refreshGlobalPlayer();
    history.push('/');
  }, []);

  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.LOADING });
  }, []);

  useEffect(() => {
    // skip avatar creation when in orbit mode (in the dumbest way possible)
    if (!useAvatars) doneCallback();
  }, [useAvatars, doneCallback]);

  return (
    <>
      <ThreeCanvas DoneComponent={Done} ErrorComponent={Error}>
        <AvatarScene />
      </ThreeCanvas>
      <CustomizationUi initial={true} onDone={doneCallback} />
      <VisualLoader />
    </>
  );
}
