import React, { useMemo, useState } from 'react';
import { BottomContainer, Container, Headline, MiddleContainer, TopContainer, Topline } from './styles';
import { Button } from 'common/components/Button';
import CategoryButton from './CategoryButton';
import { useUserStore } from 'services/UserService';
import { OptionalButton, OptionalButtonText } from './CategoryButton/styles';
import { IconContainer } from '../OptionsPanel/Option/styles';
import IconDice from '../icons/icon_dice.png';
import HBFLayout from 'common/components/HBFLayout';
import { useTranslate } from 'services/TranslationService/hooks';
import { getInitialCustomizationConfiguration } from 'services/AvatarService.new/config';

export default function CategoryPanel({ simplify, customizationConfiguration, onDone, randomize }) {
  const userName = useUserStore(state => state.user.forename);
  const [hover, setHover] = useState(false);
  const translate = useTranslate();
  const language = useUserStore(state => state.user && state.user.language);
  const initialConfig = useMemo(() => {
    return getInitialCustomizationConfiguration();
  }, [language]);

  const header = (
    <TopContainer style={{ marginBottom: '70px' }}>
      <Topline>
        {translate('avatar.hello.subline') || 'Hi'} {userName}!
      </Topline>
      <Headline>{translate('avatar.headline') || 'Create your character'}</Headline>
      <OptionalButton
        onClick={() => {
          randomize();
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <OptionalButtonText>{translate('avatar.cta.randomize') || 'Surprise me'}</OptionalButtonText>
        <IconContainer icon={IconDice} hover={hover} />
      </OptionalButton>
    </TopContainer>
  );

  const getTranslatedName = catId => {
    const category = initialConfig.find(({ id }) => id === catId);
    return category.name;
  };

  const body = !simplify ? (
    <MiddleContainer>
      {customizationConfiguration.map(category => {
        return <CategoryButton key={category.id} category={category} translated={getTranslatedName(category.id)} />;
      })}
    </MiddleContainer>
  ) : null;

  const footer = (
    <BottomContainer style={{ marginTop: '15px' }}>
      <Button
        accent
        fullWidth
        onClick={() => {
          onDone();
        }}
      >
        {translate('avatar.cta.done') || 'I am ready'}
      </Button>
    </BottomContainer>
  );

  return (
    <Container simplify={simplify}>
      <HBFLayout header={header} body={body} footer={footer} takeFullHeight fullHeightMinus={30} />
    </Container>
  );
}
